html {
  overflow-x: hidden;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /* concentric circles */
  /* background-color: #500050;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg %3E%3Ccircle fill='%23500050' cx='400' cy='400' r='600'/%3E%3Ccircle fill='%2346064b' cx='400' cy='400' r='500'/%3E%3Ccircle fill='%233c0b45' cx='400' cy='400' r='400'/%3E%3Ccircle fill='%23330d3f' cx='400' cy='400' r='300'/%3E%3Ccircle fill='%232b0e38' cx='400' cy='400' r='200'/%3E%3Ccircle fill='%23230E31' cx='400' cy='400' r='100'/%3E%3C/g%3E%3C/svg%3E");
   */

  /* waves */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1011%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(42%2c 8%2c 69%2c 1)'%3e%3c/rect%3e%3cpath d='M 0%2c230 C 57.6%2c188.4 172.8%2c39 288%2c22 C 403.2%2c5 460.8%2c144.8 576%2c145 C 691.2%2c145.2 748.8%2c23 864%2c23 C 979.2%2c23 1036.8%2c124.6 1152%2c145 C 1267.2%2c165.4 1382.4%2c129 1440%2c125L1440 560L0 560z' fill='rgba(77%2c 41%2c 143%2c 1)'%3e%3c/path%3e%3cpath d='M 0%2c363 C 96%2c382 288%2c472.2 480%2c458 C 672%2c443.8 768%2c282 960%2c292 C 1152%2c302 1344%2c464.8 1440%2c508L1440 560L0 560z' fill='rgba(100%2c 65%2c 165%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1011'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");

  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-alert .MuiAlert-icon,
.custom-alert a {
  color: #6441a5 !important;
}
